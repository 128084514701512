<template>
  <v-dialog dark fullscreen transition="slide-x-transition" v-model="opened">
    <div
      class="expande-horizontal column wrap align-top"
      style="min-heigth: 90vh; align-items: flex-start; justify-content: flex-start;"
    >
      <div class="expande-horizontal px-1 pr-3 py-3">
        <v-btn dark @click="save" icon>
          <v-icon color="grey">
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-list></v-list>
        <span
          style="font-size: 17pt; width: 100%;"
          class="fonte fonte-bold white--text"
        >
          {{ product.nome }}
        </span>
        <div class="expande-horizontal"></div>
      </div>

      <div class="expande-horizontal wrap">
        <div class="expande-horizontal wrap">
          <v-flex class="pa-3" xs12 md4>
            <div class="expande-horizontal wrap">
              <v-flex xs12>
                <div class="expande-horizontal white--text column gradient">
                  <div class="pa-3 expande-horizontal centraliza">
                    <v-icon>mdi-cash-fast</v-icon>
                    <h3 class="fonte ml-1">Resumo de Preços</h3>
                    <v-spacer></v-spacer>
                  </div>
                  <h2 class="pa-3 pt-0 pb-0 fonte">
                    {{ $helper.formataSaldo(product.preco_com_markup || 0) }}
                  </h2>
                  <span class="fonte pa-3 pt-0 pb-0">
                    Preço com Markup ( calc: {{ product.markup }} )
                  </span>
                  <v-divider class="my-3"></v-divider>
                  <h3 class="fonte pa-3 pt-0 pb-0">
                    {{ $helper.formataSaldo(product.preco_com_lp || 0) }}
                  </h3>
                  <span class="fonte pa-3 pt-0 pb-0">
                    Preço com base no lucro pretendido de
                    {{ product.lucro_pretendido }}%
                  </span>
                  <v-divider class="my-3"></v-divider>
                  <h4 class="fonte pa-3 pt-0 pb-0">
                    {{ $helper.formataSaldo(product.preco_insumos || 0) }}
                  </h4>
                  <span class="fonte pa-3 pt-0 pb-3">
                    Preço com base nos insumos
                  </span>
                  <v-card color="transparent" light class="expande-horizontal">
                    <v-flex xs12>
                      <v-form ref="formLucro">
                        <v-flex xs12 class="pa-3 pb-0">
                          <v-text-field
                            solo
                            dense
                            v-mask="['#', '##', '###']"
                            @input="calcPrecos"
                            v-model="product.lucro_pretendido"
                            prefix="Lucro pretendido:"
                            suffix="%"
                          ></v-text-field>
                        </v-flex>
                      </v-form>
                    </v-flex>
                  </v-card>
                </div>
              </v-flex>
              <v-flex class="pt-6" xs12>
                <div class="expande-horizontal">
                  <v-flex xs12>
                    <ModalSendArchive />
                    <v-form ref="formProduct">
                      <v-flex xs12 class="">
                        <v-text-field
                          outlined
                          ref="nameProduct"
                          dense
                          @keyup.enter="$refs.inputValue.focus()"
                          v-model="product.nome"
                          label="Nome do produto"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 class="">
                        <v-textarea
                          outlined
                          ref="productDescription"
                          dense
                          @keyup.enter="$refs.inputValue.focus()"
                          v-model="product.descricao"
                          label="Descrição"
                        ></v-textarea>
                      </v-flex>
                    </v-form>
                  </v-flex>
                </div>
              </v-flex>
            </div>
          </v-flex>
          <v-flex class="pa-3" xs12 md4>
            <div class="expande-horizontal wrap">
              <v-flex xs12>
                <v-window v-model="windowInsumo">
                  <v-window-item :value="1">
                    <div
                      class="expande-horizontal pa-6 white--text column gradient-insumos"
                    >
                      <div class="centraliza pb-3 expande-horizontal">
                        <v-icon>mdi-list-box-outline</v-icon>
                        <h3 class="fonte ml-1">Ficha Técnica</h3>
                        <v-spacer></v-spacer>
                      </div>
                      <v-spacer></v-spacer>
                      <div class="expande-horizontal">
                        <h1 class="fonte">
                          {{ $helper.formataSaldo(product.preco_insumos || 0) }}
                        </h1>
                      </div>
                      <div class="expande-horizontal column">
                        <span class="fonte">
                          Este produto é composto por
                          {{
                            product.ficha_tecnica
                              ? product.ficha_tecnica.length
                              : 0
                          }}
                          insumo(s), confira abaixo.
                        </span>
                        <v-btn color="primary" @click="windowInsumo = 2">
                          Adicionar insumo
                          <v-icon right>
                            mdi-chevron-right
                          </v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-window-item>
                  <v-window-item :value="2">
                    <div
                      class="expande-horizontal white--text column gradient-insumos-form"
                    >
                      <div class="expande-horizontal centraliza px-3 py-6 wrap">
                        <div class="expande-horizontal">
                          <h3 class="fonte">Pesquise e selecione o insumo:</h3>
                        </div>
                        <v-flex
                          xs12
                          v-if="get_insumos.docs.length > 0 && get_insumos.docs"
                        >
                          <v-list dark color="transparent" dense>
                            <template
                              v-for="(itemFicha, i) in get_insumos.docs"
                            >
                              <v-list-item
                                class="item-list-insumo"
                                @click="
                                  selecionaInsumoParaAdicionarAoProduto(
                                    itemFicha
                                  )
                                "
                                :key="i / 2"
                              >
                                <v-list-item-title>
                                  <span class="fonte">
                                    {{ itemFicha.nome }}
                                  </span>
                                </v-list-item-title>
                                <v-list-item-action>
                                  <v-btn
                                    x-small
                                    text
                                    @click="
                                      selecionaInsumoParaAdicionarAoProduto(
                                        itemFicha
                                      )
                                    "
                                  >
                                    Adicionar
                                    <v-icon size="15" class="mr-2"
                                      >mdi-plus-circle</v-icon
                                    >
                                  </v-btn>
                                </v-list-item-action>
                              </v-list-item>
                            </template>
                          </v-list>
                        </v-flex>
                        <div v-else>
                          <span class="fonte white--text text-center"
                            >Sem resultados, faça uma busca abaixo</span
                          >
                        </div>
                      </div>
                      <v-card
                        light
                        flat
                        class="expande-horizontal px-3 centraliza"
                      >
                        <InsumosFiltro style="color: #333" />
                        <v-btn
                          color="red lighten-2"
                          class="fonte"
                          dark
                          small
                          @click="windowInsumo = 1"
                          >Cancelar</v-btn
                        >
                      </v-card>
                    </div>
                  </v-window-item>
                  <v-window-item :value="3">
                    <div
                      class="expande-horizontal pt-3 pb-3 white--text column gradient-insumos-form"
                    >
                      <div class="expande-horizontal column px-3">
                        <div class="expande-horizontal">
                          <h3 class="fonte">Informe a porção a adicionar:</h3>
                        </div>
                        <v-flex xs12>
                          <v-list dark color="transparent" dense>
                            <v-list-item class="item-list-insumo">
                              <v-list-item-content>
                                <v-list-item-title>
                                  <span class="fonte">
                                    {{ windowInsumoForm.nome }}
                                  </span>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <span class="fonte">
                                    Unidade:
                                    {{ windowInsumoForm.unidade_de_medida }}
                                  </span>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-flex>
                        <v-flex xs12>
                          <v-form ref="formInsumo">
                            <v-flex xs12>
                              <v-text-field
                                solo
                                dense
                                :rules="[v => !!v || 'Campo obrigatório']"
                                ref="quantidadeField"
                                v-mask="[
                                  '#',
                                  '#,##',
                                  '##,##',
                                  '###,##',
                                  '####,##'
                                ]"
                                @keyup.enter.prevent="finalizar_e_salvar_insumo"
                                :prefix="
                                  `Quantidade em ${windowInsumoForm.unidade_de_medida}: `
                                "
                                v-model="windowInsumoForm.quantidade"
                              ></v-text-field>
                            </v-flex>
                          </v-form>
                        </v-flex>
                      </div>
                      <div class="expande-horizontal centraliza">
                        <v-btn
                          x-small
                          text
                          dark
                          class="fonte mr-2"
                          @click="windowInsumo = 1"
                        >
                          <v-icon size="13" class="mr-1"
                            >mdi-chevron-left</v-icon
                          >
                          Cancelar</v-btn
                        >
                        <v-btn
                          color="green"
                          dark
                          small
                          class="fonte"
                          @click="finalizar_e_salvar_insumo"
                          >Finalizar e adicionar
                          <v-icon>mdi-save</v-icon></v-btn
                        >
                      </div>
                    </div>
                  </v-window-item>
                </v-window>
              </v-flex>
              <v-flex xs12>
                <v-flex xs12>
                  <h5 class="fonte white--text mt-3 mb-2 pl-1">
                    Confira a ficha técnica abaixo:
                  </h5>
                </v-flex>
                <v-list
                  color="transparent"
                  dark
                  v-if="product.ficha_tecnica && product.ficha_tecnica.length"
                  dense
                >
                  <template v-for="(itemFicha, i) in product.ficha_tecnica">
                    <v-list-item
                      class="item-list"
                      @click="() => {}"
                      :key="i / 2"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <span class="fonte">
                            {{ itemFicha.quantidade }}
                            {{ itemFicha | filterUnidade }} de
                            {{ itemFicha.nome }}
                          </span>
                        </v-list-item-title>
                        <v-list-item-subtitle
                          class="light-green--text fonte fonte-bold"
                        >
                          + {{ itemFicha | calcGramaTotal }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon @click="removerInsumo(false, itemFicha, i)">
                          <v-icon>mdi-minus</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list>
                <div v-else class="expande-horizontal centraliza">
                  <span class="fonte grey--text pa-6 text-center">
                    Este produto ainda não possui insumos, adicione um acima.
                  </span>
                </div>
              </v-flex>
            </div>
          </v-flex>
          <!-- parteee para adicionar adicionais -->
          <v-flex class="pa-3" xs12 md4>
            <div class="expande-horizontal wrap">
              <v-flex xs12>
                <v-window v-model="windowAdicional">
                  <v-window-item :value="1">
                    <div
                      class="expande-horizontal px-6 pt-3 pb-3 white--text column gradient-adicionais-form"
                    >
                      <div class="expande-horizontal pb-3">
                        <v-icon>mdi-format-list-group</v-icon>
                        <h3 class="fonte">
                          Grupos de Adicionais
                        </h3>
                        <v-spacer></v-spacer>
                      </div>
                      <span class="fonte">
                        Este produto tem
                        {{ product.adicionais ? product.adicionais.length : 0 }}
                        grupo(s) de adicional(is) cadastrado(s).
                      </span>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="fonte mt-3"
                        color="primary"
                        @click="windowAdicional = 2"
                      >
                        Novo Grupo
                        <v-icon right>
                          mdi-chevron-right
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-window-item>
                  <v-window-item :value="2">
                    <div
                      class="expande-horizontal white--text column pt-3 pb-3 gradient-adicionais-form"
                    >
                      <div class="expande-horizontal px-3 wrap">
                        <v-flex xs12>
                          <v-form ref="formGrupoAdicional">
                            <v-flex xs12>
                              <div class="expande-horizontal wrap">
                                <v-flex class="pr-3" xs8>
                                  <span class="fonte white--text"
                                    >Nome do grupo</span
                                  >
                                  <v-text-field
                                    solo
                                    color="#fff"
                                    dense
                                    clearable
                                    placeholder="Ex: Adicional de Molho"
                                    :rules="[v => !!v || 'Campo obrigatório']"
                                    ref="nomeAdicionalField"
                                    v-model="windowAdicionalForm.nome_grupo"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex class="pt-3" xs4>
                                  <v-switch
                                    v-model="windowAdicionalForm.obrigatorio"
                                    label="Obrigatório"
                                  ></v-switch>
                                </v-flex>
                              </div>
                            </v-flex>
                            <v-flex xs12>
                              <div class="expande-horizontal wrap">
                                <v-flex class="pr-3" xs8>
                                  <span class="fonte white--text"
                                    >Máximo de itens</span
                                  >
                                  <v-text-field
                                    solo
                                    color="#fff"
                                    dense
                                    clearable
                                    v-mask="['#', '##']"
                                    placeholder="Ex: 2"
                                    :rules="[v => !!v || 'Campo obrigatório']"
                                    ref="maximoAdicionalField"
                                    v-model="windowAdicionalForm.maximo"
                                  ></v-text-field>
                                </v-flex>
                              </div>
                            </v-flex>
                          </v-form>
                        </v-flex>
                        <v-flex xs12>
                          <v-flex xs12>
                            <h4 class="fonte">Itens neste grupo:</h4>
                          </v-flex>
                          <v-flex xs12>
                            <v-list
                              class="fonte ma-0"
                              style="border-radius: 6px;"
                              v-if="windowAdicionalForm.itens.length"
                            >
                              <template
                                v-for="(item, i) in windowAdicionalForm.itens"
                              >
                                <v-list-item :key="i / 5">
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{ item.quantidade }}
                                      {{ calcUnidadeDeMedida(item) }} de
                                      {{ item.nome }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                      {{ $helper.formataSaldo(item.preco) }}
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-btn
                                      icon
                                      @click="
                                        removerAdicionalDoGrupo(true, item, i)
                                      "
                                    >
                                      <v-icon>mdi-minus</v-icon>
                                    </v-btn>
                                  </v-list-item-action>
                                </v-list-item>
                              </template>
                            </v-list>
                            <div
                              v-else
                              class="expande-horizontal centraliza pa-3"
                              style="min-height: 100px;"
                            >
                              <span
                                >Você ainda não adicionou nada, adicione
                                abaixo.</span
                              >
                            </div>
                          </v-flex>
                        </v-flex>
                        <v-flex class="mt-3" xs12>
                          <v-card
                            v-if="!windowInsumoForm.nome"
                            class="pb-3"
                            color="#333"
                          >
                            <div
                              class="expande-horizontal wrap centraliza px-3 pl-0"
                            >
                              <v-flex class="pa-3" xs12>
                                <h4 class="fonte white--text">
                                  Adicione itens ao grupo:
                                </h4>
                              </v-flex>
                              <v-divider></v-divider>
                              <v-flex
                                xs12
                                v-if="
                                  get_insumos.docs.length > 0 &&
                                    get_insumos.docs
                                "
                              >
                                <v-list dark color="transparent" dense>
                                  <template
                                    v-for="(itemFicha, i) in get_insumos.docs"
                                  >
                                    <v-list-item
                                      @click="
                                        selecionaAdicionalParaAdicionarAoProduto(
                                          itemFicha
                                        )
                                      "
                                      :key="i / 3"
                                    >
                                      <v-list-item-title>
                                        <span class="fonte">
                                          {{ itemFicha.nome }}
                                        </span>
                                      </v-list-item-title>
                                      <v-list-item-action>
                                        <v-btn
                                          x-small
                                          text
                                          @click="
                                            selecionaAdicionalParaAdicionarAoProduto(
                                              itemFicha
                                            )
                                          "
                                        >
                                          Adicionar
                                          <v-icon size="15" class="mr-2"
                                            >mdi-plus-circle</v-icon
                                          >
                                        </v-btn>
                                      </v-list-item-action>
                                    </v-list-item>
                                  </template>
                                </v-list>
                              </v-flex>
                              <div v-else>
                                <span class="fonte white--text text-center"
                                  >Sem resultados, faça uma busca abaixo</span
                                >
                              </div>
                            </div>
                            <v-card light flat color="transparent">
                              <v-flex xs12>
                                <InsumosFiltro class="pl-3" />
                              </v-flex>
                            </v-card>
                            <v-btn
                              class="ml-3 fonte"
                              x-small
                              light
                              @click="windowAdicional = 1"
                              >Cancelar</v-btn
                            >
                            <v-btn
                              class="ml-3 fonte"
                              x-small
                              light
                              @click="finalizar_e_salvar_grupo_de_adicional"
                              >Finalizar e salvar grupo</v-btn
                            >
                          </v-card>
                          <div
                            v-else
                            class="expande-horizontal white--text pb-6 column"
                            style="background: #333"
                          >
                            <div class="expande-horizontal column px-3">
                              <v-flex xs12>
                                <v-list dark color="transparent" dense>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        <span class="fonte">
                                          {{ windowInsumoForm.nome }}
                                        </span>
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        <span class="fonte">
                                          Unidade:
                                          {{
                                            windowInsumoForm.unidade_de_medida
                                          }}
                                        </span>
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-flex>
                              <v-flex class="fonte" xs12>
                                <v-form ref="formAdicional">
                                  <v-flex xs12>
                                    <v-text-field
                                      dense
                                      solo
                                      dark
                                      :rules="[v => !!v || 'Campo obrigatório']"
                                      ref="quantidadeDoAdicionalField"
                                      v-mask="[
                                        '#',
                                        '#,##',
                                        '##,##',
                                        '###,##',
                                        '####,##'
                                      ]"
                                      :placeholder="
                                        `Porção em ${this.calcUnidadeDeMedida(
                                          windowInsumoForm
                                        ) || ''}`
                                      "
                                      v-model="windowInsumoForm.quantidade"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12>
                                    <v-text-field
                                      dense
                                      solo
                                      dark
                                      placeholder="Nome personalizado"
                                      :rules="[v => !!v || 'Campo obrigatório']"
                                      ref="descricaoPersonalizadaField"
                                      v-model="
                                        windowInsumoForm.descricao_personalizada
                                      "
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12>
                                    <v-text-field
                                      dense
                                      solo
                                      dark
                                      :rules="[v => !!v || 'Campo obrigatório']"
                                      ref="precoAdicionalField"
                                      @keyup.enter.prevent="
                                        adicionaItemAdicionalAoForm
                                      "
                                      v-mask="[
                                        '#',
                                        '#,##',
                                        '##,##',
                                        '###,##',
                                        '####,##'
                                      ]"
                                      placeholder="Preço R$"
                                      v-model="windowInsumoForm.preco"
                                    ></v-text-field>
                                  </v-flex>
                                </v-form>
                              </v-flex>
                            </div>
                            <div class="expande-horizontal centraliza">
                              <v-btn
                                x-small
                                text
                                dark
                                class="fonte mr-2"
                                @click="windowInsumoForm = {}"
                              >
                                <v-icon size="13" class="mr-1"
                                  >mdi-chevron-left</v-icon
                                >
                                Cancelar</v-btn
                              >
                              <v-btn
                                color="green"
                                dark
                                x-small
                                class="fonte"
                                @click="adicionaItemAdicionalAoForm"
                                >Finalizar e salvar
                                <v-icon>mdi-save</v-icon></v-btn
                              >
                            </div>
                          </div>
                        </v-flex>
                      </div>
                    </div>
                  </v-window-item>
                </v-window>
              </v-flex>
              <v-flex xs12>
                <v-flex xs12>
                  <h5 class="fonte white--text mt-3 mb-2 pl-1">
                    Confira os grupos cadastrados abaixo:
                  </h5>
                </v-flex>
                <v-list
                  color="transparent"
                  v-if="product.adicionais && product.adicionais.length"
                  dense
                >
                  <template v-for="(grupo, i) in product.adicionais">
                    <v-list-item class="item-list-adicional" :key="i / 2">
                      <v-list-item-content>
                        <v-list-item-title>
                          <span class="fonte fonte-bold"
                            >{{ grupo.nome_grupo }}
                          </span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <span class="fonte"
                            >{{ grupo.itens.length }} itens
                          </span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <span class="fonte"
                            >{{
                              grupo.obrigatorio ? "Obrigatório" : "Opcional"
                            }}
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn @click="removerGrupo(false, grupo, i)">
                          <v-icon>mdi-minus</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list>
                <div v-else class="expande-horizontal centraliza">
                  <span class="fonte grey--text pa-6 text-center">
                    Este produto ainda não possui adicionais, adicione um acima.
                  </span>
                </div>
              </v-flex>
            </div>
          </v-flex>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalSendArchive from "../../shared/components/ModalSendArchive.vue";
import InsumosFiltro from "../../Insumos/components/Filtros.vue";
export default {
  components: { ModalSendArchive, InsumosFiltro },
  props: {
    product: {
      type: Object,
      default: () => {}
    },
    save: {
      type: Function,
      default: () => {}
    }
  },
  filters: {
    calcGramaTotal(val) {
      const quantidade = parseFloat(val.quantidade || 0);
      const preco_de_compra = parseFloat(val.preco_de_compra || 0);
      const preco_da_grama = preco_de_compra / val.divisor;
      const total_de_entrada = quantidade * preco_da_grama;
      return total_de_entrada.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    },
    filterUnidade(value) {
      if (value.unidade_de_medida == "un") {
        return "unidade(s)";
      } else if (value.unidade_de_medida == "kg") {
        return "quilo(s)";
      } else if (
        value.unidade_de_medida == "g" ||
        value.unidade_de_medida == "GRAMA"
      ) {
        return "grama(s)";
      } else if (value.unidade_de_medida == "l") {
        return "litro(s)";
      } else if (value.unidade_de_medida == "ml") {
        return "mililitro(s)";
      } else {
        return value.unidade_de_medida;
      }
    }
  },
  data() {
    return {
      opened: false,
      windowInsumo: 1,
      windowAdicional: 1,
      windowInsumoForm: {},
      windowAdicionalForm: {
        itens: []
      }
    };
  },
  watch: {
    windowInsumo(val) {
      if (val == 2) {
        this.get_insumos_filtros.limitPerPage = 2;
        this.listar_insumos();
      }
    },
    windowAdicional(val) {
      if (val == 2) {
        this.get_insumos_filtros.limitPerPage = 2;
        this.listar_insumos();
        this.windowInsumoForm = {};
      }
    }
  },
  computed: {
    ...mapGetters(["get_insumos", "get_insumos_filtros", "get_invoices"])
  },
  methods: {
    ...mapActions([
      "createConfirmAction",
      "listar_insumos",
      "createGlobalMessage"
    ]),
    calcUnidadeDeMedida(value) {
      if (value.unidade_de_medida == "un") {
        return "unidade(s)";
      } else if (value.unidade_de_medida == "kg") {
        return "quilo(s)";
      } else if (
        value.unidade_de_medida == "g" ||
        value.unidade_de_medida == "GRAMA"
      ) {
        return "grama(s)";
      } else if (value.unidade_de_medida == "l") {
        return "litro(s)";
      } else if (value.unidade_de_medida == "ml") {
        return "mililitro(s)";
      } else {
        return value.unidade_de_medida;
      }
    },
    valida_form() {
      if (this.$refs.form.validate()) {
        this.get_menu._id ? this.atualizar_menu() : this.criar_menu();
      }
    },
    open() {
      this.opened = false;
      this.opened = true;
    },
    closeModal() {
      this.opened = false;
    },
    removerInsumo(confirmation, item, index) {
      if (confirmation) {
        this.product.ficha_tecnica.splice(index, 1);
        this.createGlobalMessage({
          timeout: 6000,
          icon: "mdi-success",
          message: `Removemos ${item.quantidade} ${this.calcUnidadeDeMedida(
            item
          )} de ${item.nome} do produto ${this.product.nome} com sucesso!`,
          type: "success"
        });
        this.calcPrecos();
      } else {
        this.createConfirmAction({
          message: `Deseja remover ${
            item.quantidade
          } ${this.calcUnidadeDeMedida(item)} de ${item.nome}?`,
          icone: "mdi-warning",
          isScoped: true,
          action: () => this.removerInsumo(true, item, index),
          action_value: "ok"
        });
      }
    },
    removerGrupo(confirmation, item, index) {
      if (confirmation) {
        this.product.adicionais.splice(index, 1);
        this.createGlobalMessage({
          timeout: 6000,
          icon: "mdi-success",
          message: `Grupo removido com sucesso`,
          type: "success"
        });
        this.$forceUpdate();
      } else {
        this.createConfirmAction({
          message: `Deseja remover este grupo?`,
          icone: "mdi-warning",
          isScoped: true,
          action: () => this.removerGrupo(true, item, index),
          action_value: "ok"
        });
      }
    },
    removerAdicionalDoGrupo(confirmation, item, index) {
      if (confirmation) {
        this.windowAdicionalForm.itens.splice(index, 1);
        this.createGlobalMessage({
          timeout: 6000,
          icon: "mdi-success",
          message: `Removemos ${item.quantidade} ${this.calcUnidadeDeMedida(
            item
          )} de ${item.nome} do grupo ${
            this.windowAdicionalForm.nome_grupo
          } com sucesso!`,
          type: "success"
        });
      } else {
        this.createConfirmAction({
          message: `Deseja remover ${
            item.quantidade
          } ${this.calcUnidadeDeMedida(item)}?`,
          icone: "mdi-warning",
          isScoped: true,
          action: () => this.removerAdicionalDoGrupo(true, item, index),
          action_value: "ok"
        });
      }
    },
    selecionaInsumoParaAdicionarAoProduto(item) {
      this.windowInsumoForm = item;
      this.windowInsumo = 3;
      setTimeout(() => {
        this.$refs.quantidadeField.focus();
      }, 200);
    },
    selecionaAdicionalParaAdicionarAoProduto(item) {
      this.windowInsumoForm = item;
    },
    adicionaItemAdicionalAoForm() {
      if (this.$refs.formAdicional.validate()) {
        var adicionais = [];
        const item = this.windowInsumoForm;
        if (Array.isArray(this.windowAdicionalForm.itens)) {
          adicionais = [...this.windowAdicionalForm.itens, item];
        } else {
          adicionais = [item];
        }
        this.windowAdicionalForm.itens = adicionais;
        this.windowInsumoForm = {};
        this.$forceUpdate();
        this.createGlobalMessage({
          timeout: 3000,
          icon: "mdi-success",
          message: `Item adicionado com sucesso!`,
          type: "success"
        });
      }
    },
    finalizar_e_salvar_insumo() {
      if (this.$refs.formInsumo.validate()) {
        if (!this.product.ficha_tecnica) {
          this.product.ficha_tecnica = [];
        }
        this.product.ficha_tecnica.push({
          ...this.windowInsumoForm,
          quantidade: this.windowInsumoForm.quantidade
        });
        this.windowInsumo = 1;
        this.calcPrecos();
        this.createGlobalMessage({
          timeout: 6000,
          icon: "mdi-success",
          message: `Adicionamos ${
            this.windowInsumoForm.quantidade
          } ${this.calcUnidadeDeMedida(this.windowInsumoForm)} de ${
            this.windowInsumoForm.nome
          } ao produto ${this.product.nome} com sucesso!`,
          type: "success"
        });
      }
    },
    calcPrecos() {
      const calcPreco = val => {
        const quantidade = parseFloat(val.quantidade);
        const preco_de_compra = parseFloat(val.preco_de_compra);
        const preco_da_grama = preco_de_compra / val.divisor;
        const total_de_entrada = quantidade * preco_da_grama;
        return total_de_entrada;
      };
      let total = 0;
      const ficha_tecnica = this.product.ficha_tecnica || [];
      ficha_tecnica.map(estoque => {
        total += calcPreco(estoque);
      });

      const preco_com_lp =
        total + (total / 100) * this.product.lucro_pretendido;
      // const preco_com_markup =
      this.product.preco_insumos = parseFloat(total.toFixed(2));
      this.product.preco_com_lp = parseFloat(preco_com_lp.toFixed(2));
      this.product.preco_com_markup = this.calcMarkup(
        parseFloat(total.toFixed(2))
      );
    },
    calcMarkup(val) {
      const DV = 15;
      const DF = 17;

      const LP = parseFloat(this.product.lucro_pretendido || 0);

      const preco_com_markup = 100 / (100 - (DV + DF + LP));

      this.product.markup = preco_com_markup;
      return preco_com_markup * val;
    },
    finalizar_e_salvar_grupo_de_adicional() {
      if (this.$refs.formGrupoAdicional.validate()) {
        if (Array.isArray(this.product.adicionais)) {
          this.product.adicionais.push(this.windowAdicionalForm);
        } else {
          this.product.adicionais = [this.windowAdicionalForm];
        }
        this.windowInsumo = 1;
        this.windowAdicional = 1;
        this.windowAdicionalForm = { itens: [] };
        this.windowInsumoForm = {};
        this.createGlobalMessage({
          timeout: 6000,
          icon: "mdi-success",
          message: `Adicionamos o novo grupo com sucesso!`,
          type: "success"
        });
      }
    },
    calc_lucro_pretendido(val) {
      const lucro = parseFloat(val) || 0;
      this.product.preco_final = this.valorTotalComBaseNosInsumos;
      this.product.preco_final += (this.preco_final / 100) * lucro;
      this.product.preco_final = parseFloat(this.preco_final.toFixed(2));
    }
  }
};
</script>

<style>
.item-list {
  border: 1px solid #f2c94c;
  border-radius: 5px;
  margin-bottom: 6px;
}
.item-list-adicional {
  border: 1px solid #0575e6;
  border-radius: 5px;
  margin-bottom: 6px;
}
.item-list-insumo {
  border: 1px solid #fff;
  border-radius: 5px;
  margin-bottom: 6px;
}

.align-top {
  align-items: flex-start;
  justify-content: flex-start;
  background: #333;
  min-height: 100vh;
}
.gradient {
  background: #56ab2f; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #56ab2f,
    #a8e063
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #56ab2f, #a8e063);
  border-radius: 5px;
  min-height: 200px;
}
.gradient-insumos {
  background: #f2994a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f2994a,
    #f2c94c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f2994a, #f2c94c);
  border-radius: 5px;
  height: 200px;
  justify-content: center;
  align-items: center;
}
.gradient-insumos-form {
  background: #f2994a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #00a5db,
    #3eb4df
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #00a5db, #3eb4df);
  border-radius: 5px;
  min-height: 200px;
  justify-content: center;
  align-items: center;
}
.gradient-adicionais-form {
  background: #0575e6; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #0575e6,
    #021b79
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0575e6, #021b79);
  border-radius: 5px;
  min-height: 200px;
}
.cart-resume-price {
  height: 20vh;
}
</style>
