<template>
  <div class="expande-horizontal wrap pa-3">
    <v-flex xs12 class="">
      <v-text-field
        dense
        solo
        background-color="#f2f2f2"
        small
        flat
        class="fonte-subtitulo fonte-italica"
        label="Busque pelo nome da categoria..."
        v-model="get_produtos_filtros.nome"
        clearable
        hide-details
        :color="$theme.primary"
        @input="debounceSearch"
      ></v-text-field>
    </v-flex>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
var _ = require("lodash");
export default {
  data() {
    return {
      produto: false,
      produto1: false,
      produto2: false,
      dates1: "",
      dates2: ""
    };
  },
  computed: {
    ...mapGetters(["get_produtos", "get_setores", "get_produtos_filtros"]),
    computedInitialDate: {
      get() {
        const data = this.$moment(this.get_produtos_filtros.dataInicio).format(
          "DD/MM/YYYY"
        );
        if (data === "Data inválida") {
          return "";
        } else {
          return data;
        }
      },
      set(value) {
        this.get_produtos_filtros.dataInicio = value;
      }
    },
    computedFinalDate: {
      get() {
        const data = this.$moment(this.get_produtos_filtros.dataFim).format(
          "DD/MM/YYYY"
        );
        if (data === "Data inválida") {
          return "";
        } else {
          return data;
        }
      },
      set(value) {
        this.get_produtos_filtros.dataFim = value;
      }
    }
  },
  methods: {
    ...mapActions(["listar_produtos"]),
    debounceSearch() {
      const x = _.debounce(this.listar_produtos, 800);
      x();
    }
  }
};
</script>
